<template>
  <section class="sectionPadre" id="modalInicio">
    <div class="sectionPadre__sectionHijo">
      <div class="contenedor">
        <img
          loading="lazy"
          class="contenedor__logo"
          width="77"
          height="77"
          src="https://d3jotkb1j9zbaa.cloudfront.net/logo_white.webp"
          alt="Logo modal inicio Bayer"
          title="Logo modal inicio Bayer"
        />
      </div>
      <hr class="barra_separacion" />

      <div class="profesionales">
        <p class="profesionales__titulo">Para profesionales sanitarios</p>
        <div class="profesionales__contenedor">
          <p class="profesionales__texto">
            La información que figura en la página web está dirigida
            exclusivamente a profesionales sanitarios facultados para prescribir
            o dispensar medicamentos, por lo que requiere una formación
            especializada para su correcta interpretación. El producto
            mencionado en ésta página web puede tener una ficha técnica
            autorizada diferente en otros países. La información de producto
            ofrecida en esta página web está dirigida sólo a profesionales
            sanitarios residentes en España. Accediendo a esta página web,
            declaro que soy profesional sanitario y que he leído y acepto las
            <a href="/politica-de-privacidad">Condiciones de uso</a> y la
            <a href="/politica-de-privacidad">Política de privacidad</a>
          </p>
          <span class="profesionales__flecha" @click="ocultarModal()"></span>
        </div>
      </div>

      <div class="publico">
        <p class="publico__titulo">Para público general</p>
        <a
          href="https://www.bayer.com/es/es/espana-home"
          class="publico__contenedor"
        >
          <p class="publico__texto">
            Si no eres un profesional sanitario y quieres aprender acerca de
            esta campaña haz <span>click aquí</span>
          </p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
// import { modalInicio } from "@/mixins/ModalInicio.js";
export default {
  name: "ModalInicio",
  // mixins: [modalInicio],
  methods: {
    ocultarModal() {
      // this.normalizeScroll();
      document.querySelector(".sectionPadre").style.zIndex = "-1";
      document.querySelector(".sectionPadre").style.opacity = "0";
      document.getElementsByTagName("html")[0].style.overflowY = "scroll";

      if (!this.getCookie("ba-san")) {
        document.cookie =
          "ba-san=true;expires=" +
          new Date(new Date().getTime() + 60 * 60 * 1000 * 24).toGMTString() +
          ";path=/";
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
  },
};
</script>

<style>
</style>